import React, { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { PageProps } from 'gatsby'
import { Box, Flex } from '@chatterbug/aaron'
import { Helmet } from 'react-helmet'

import theme from 'src/lib/theme'
import { getStreamsAppUrlsWithoutTracking } from 'src/lib/app'
import { PageContext } from 'src/types'
import { trackStreamsWebPromotion } from 'src/lib/tracking'
import StreamsLogo from 'src/components/StreamsLogo/StreamsLogo'
import PageContainer from 'src/components/PageContainer/PageContainer'
import Layout from 'src/components/Layout/Layout'
import MobileAppLinks from 'src/components/MobileAppLinks/MobileAppLinks'

const ExistingUserPage: React.FC<PageProps<unknown, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()
  const mobileUrls = getStreamsAppUrlsWithoutTracking()

  useEffect(() => {
    trackStreamsWebPromotion('redirected-to', 'existing-user')
  }, [])

  return (
    <Layout
      pageContext={pageContext}
      background={theme.namedColors.streamsBackground}
      hideSiteHeader
      hideSiteFooter
    >
      <Helmet
        title={t('Existing User')}
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <PageContainer>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          pt={80}
        >
          <StreamsLogo height={57} iconOnly />
          {/* text */}
          <Box as="h3" variant="text.h3" py="3x" color="black0">
            {t("Looks like you're paying for Streams already.")}
          </Box>
          <Box
            as="p"
            variant="text.paragraph"
            color="black0"
            textAlign="center"
            mb="5x"
            dangerouslySetInnerHTML={{
              __html: t(
                "As an already paying subscriber you can't take part in this offer.<br/><br/>Don't have Streams yet? Then download it today!"
              ),
            }}
          />
          {/* mobile store buttons, row on desktop, column on mobile */}
          <Box display={{ laptop: 'none' }}>
            <MobileAppLinks
              iosAppUrls={mobileUrls.ios}
              androidAppUrls={mobileUrls.android}
              layout="column"
            />
          </Box>
          <Box display={{ _: 'none', laptop: 'block' }}>
            <MobileAppLinks
              iosAppUrls={mobileUrls.ios}
              androidAppUrls={mobileUrls.android}
            />
          </Box>
        </Flex>
      </PageContainer>
    </Layout>
  )
}

export default ExistingUserPage
